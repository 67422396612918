<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Deals Pending Approve
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-card-title>
          <v-layout wrap justify-start v-if="user.length > 0">
            <template v-for="(item, i) in user">
              <v-flex xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
                <v-layout wrap py-2>
                  <v-flex xs12 sm12 md12 lg6>
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
                        <v-img
                          :src="baseURL + item.product.photos[0]"
                          aspect-ratio="1"
                          contain
                          height="100px"
                        ></v-img>
                      </v-flex>
                      <v-flex xs12 sm9 md9 lg9 xl9>
                        <v-layout wrap justify-center fill-height>
                          <v-flex xs12>
                            <v-layout wrap justify-start>
                              <v-flex lg12 text-left>
                                <router-link style="text-decoration:none"
                                  :to="'/productDetails/' + item.product._id"
                                >
                                  <span style="font-size: 16px; color: #000000">
                                    {{ item.product.productname }}
                                  </span>
                                </router-link>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 align-self-end>
                            <v-layout wrap justify-start>
                              <v-flex xs6 sm3 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Total Stock
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 20px">
                                      {{ item.product.stockquantity }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs6 sm3 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Price
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 20px">
                                      {{ item.product.productcost }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <!-- <v-flex xs6 sm3 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left lg12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                      >Markup Price</span
                                    >
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap>
                                  <v-flex text-left lg12>
                                    <span style="font-size: 20px">{{
                                      item.product.profitmargin
                                    }}</span>
                                  </v-flex>
                                </v-layout>
                              </v-flex> -->
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex text-left xs1 text-center hidden-md-and-down>
                        <v-divider vertical></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex text-left xs12 md12 lg6>
                    <v-layout wrap justify-space-between fill-height>
                      <v-flex sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex xs6 sm12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Available Stock
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 text-left align-self-start>
                            <span style="font-size: 20px">
                              {{ item.product.stockquantity }}
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 align-self-end>
                            <v-btn
                              dark
                              small
                              tile
                              depressed
                              :ripple="false"
                              :color="
                                item.product.instock ? '#FFBC6E' : '#EB6359'
                              "
                              style="text-transform: none"
                            >
                              <span
                                style="font-size: 14px"
                                v-if="item.product.instock"
                              >
                                <v-icon>mdi-check</v-icon>
                                Available
                              </span>
                              <span style="font-size: 14px" v-else>
                                <v-icon>mdi-close</v-icon>
                                Out of Stock
                              </span>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex sm8 md7 lg7 xl7 text-left>
                        <v-layout wrap justify-start fill-height>
                          <v-flex xs4 md4 lg4 sm4 xl4 align-self-start>
                            <v-layout wrap justify-start>
                              <v-flex xs12 text-left>
                                <span style="color: #8d8d8d; font-size: 14px">
                                  Deal Type
                                </span>
                              </v-flex>
                              <v-flex text-left xs12>
                                <span style="font-size: 14px">
                                  <span v-if="item.dealtype">
                                    {{ item.dealtype }}
                                  </span>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex
                            xs4
                            md4
                            lg4
                            sm4
                            xl4
                            text-left
                            align-self-start
                          >
                            <v-layout wrap justify-start>
                              <v-flex text-left xs12>
                                <span style="color: #8d8d8d; font-size: 14px">
                                  Deal Status
                                </span>
                              </v-flex>
                              <v-flex text-left xs12>
                                <span style="color: #3bc17a; font-size: 14px">
                                  <span v-if="item.isadminapproved">
                                    Approved
                                  </span>
                                  <span v-else style="color: red">
                                    Pending
                                  </span>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs4 md4 lg4 sm4 xl4 align-self-start>
                            <v-layout wrap justify-start>
                              <v-flex xs12 text-left>
                                <span style="color: #8d8d8d; font-size: 14px">
                                  Shop
                                </span>
                              </v-flex>
                              <v-flex text-left xs12>
                                <span style="font-size: 14px">
                                  <span v-if="item.product.seller.organization">
                                    {{ item.product.seller.organization }}
                                  </span>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 align-self-start>
                            <v-layout wrap justify-center pt-4 fill-height>
                              <!-- <v-flex xs4 text-left align-self-end>
                                <v-layout wrap justify-center>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Offer Price
                                    </span>
                                  </v-flex>
                                  <v-flex text-lwft xs12>
                                    <span
                                      style="color: #3bc17a; font-size: 20px"
                                    >
                                      {{ item.price }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex> -->
                              <v-flex xs6 text-center align-self-start>
                                <v-btn
                                  tile
                                  block
                                  small
                                  outlined
                                  color="warning"
                                  style="text-transform: none"
                                  @click="editSlider(item)"
                                >
                                  <span style="color: #000; font-size: 14px">
                                    Edit & Approve
                                  </span>
                                </v-btn>
                              </v-flex>
                              <v-flex xs6 px-5 text-center align-self-end>
                                <v-dialog
                                  v-model="item.delete"
                                  max-width="600px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      tile
                                      block
                                      small
                                      outlined
                                      color="warning"
                                      style="text-transform: none"
                                      v-on="on"
                                      v-bind="attrs"
                                    >
                                      <span
                                        style="color: #000; font-size: 14px"
                                      >
                                        Delete
                                      </span>
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title
                                      >Are you sure you want to delete this
                                      Deal?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="item.delete = false"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="deleteItem(item)"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <br />
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 xl11 :key="i" py-4>
                <v-divider></v-divider>
              </v-flex>
            </template>
            <v-dialog v-model="editdialog" max-width="600px">
              <v-card>
                <v-card-title
                  >Are you sure you want to approve this deal?</v-card-title
                >
                <v-col cols="12">
                  <v-select
                    outlined
                    :items="items"
                    v-model="editingitem.dealtype"
                    label="Edit Deal"
                  ></v-select>
                </v-col>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editdialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="approve(editingitem._id)"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
          <v-layout wrap justify-center pt-10 v-else>
            <v-flex xs12>
              <span style="font-size: 25px; color: #000000">
                Oops! No Deals Found
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center v-if="pages > 1">
            <v-flex xs12>
              <v-pagination
                v-model="currentPage"
                :length="pages"
                :total-visible="7"
                color="#FF0000"
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      search: "",
      msg: "",
      currentPage: 1,
      showsnackbar: false,
      ServerError: false,
      offer: "",
      pages: 0,
      editingitem: "",
      editdialog: false,
      count: 10,
      totalData: 0,
      totalRows: 0,
      user: [],
      items: ["Buy 2 Get 1 Free", "Buy 3 above 5000 INR at 3000 INR"],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/deal/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
          approved: false,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.dealtype = this.user.dealtype;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    approve() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["dealtype"] = this.editingitem.dealtype;
      axios({
        url: "/home/deal/approve",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Approve Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/deal/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rowClick(item) {
      this.$router.push("/productDetails/" + item._id);
    },
  },
};
</script>
